/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/gallery/item';
import { Button } from 'components/modal/modal.css.js';
import { Container } from './gallery.css';
import { useExamplesData } from 'hooks/useExamplesData';
import { Link } from 'gatsby';

const Gallery = () => {
  const examples = useExamplesData();
  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '4rem' }}
      >
        <h1 style={{ fontSize: '32px', fontWeight: '400' }}>Latest Examples</h1>
      </div>
      <Container>
        {examples.slice(0, 3).map(({ node }) => (
          <Link
            style={{ textDecoration: 'none', color: 'inherit', height: '100%' }}
            key={node.id}
            to={node.frontmatter.path}
          >
            <Item {...node.frontmatter} style={{ height: '100%' }} />
          </Link>
        ))}
        <div />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button href="/examples">See More Examples</Button>
        </div>
        <div />
      </Container>
    </>
  );
};

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
