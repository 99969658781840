/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import IO from '../../../components/io';
import { Figure, Title } from './item.css';

const Item = ({ title, featuredImage }) => (
  <IO rootMargin="-50px">
    {({ isVisible }) => (
      <Figure isVisible={isVisible}>
        <Img
          fluid={featuredImage ? featuredImage.childImageSharp.fluid : {}}
          alt={title}
          style={{
            boxShadow: '0px 5px 4px -3px lightgray',
          }}
        />
        <figcaption>
          <Title>{title}</Title>
        </figcaption>
      </Figure>
    )}
  </IO>
);

Item.propTypes = {
  title: PropTypes.string,
  featuredImage: PropTypes.object.isRequired,
};

export default Item;
