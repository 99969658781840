import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import breakpoints from '../../constants/breakpoints';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  padding: 4rem 8rem;
  // background: linear-gradient(#e0e0e0, #b3b3b3);
  margin: 2rem 0;

  @media (max-width: ${breakpoints.PHONE}px) {
    padding: 0 2rem;
  }

  ${MEDIA.TABLET`
    display: block;
  `};
`;
