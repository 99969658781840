/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import { Button } from '../components/modal/modal.css.js';
import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <Layout>
    <Box>
      <Title as="h2" size="large">
        {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
      </Title>
      <Button href="blastFoam_User_Guide.pdf">User Guide</Button>
      <Button href="/documentation">Documentation</Button>
    </Box>
    <div style={{ margin: 'auto' }}>
      <Gallery />
    </div>

    {/* <Box>
      <ul>
        {data.allTwitterSearchTweetsBlastFoamQuery.edges.map(({ node }) => (
          <li
            key={node.id}
            style={{
              margin: '0 auto 10px auto',
              padding: '3px',
            }}
          >
            <div>
              <h2>@{node.user.screen_name}</h2>
              <p>{node.full_text}</p>
              <p>
                {node.retweet_count} Retweets, {node.favorite_count} Favorites
              </p>
            </div>
          </li>
        ))}
      </ul>
      <ul>
        {data.allTwitterStatusesUserTimelineSynthetikQuery.edges.map(
          ({ node }) => (
            <li
              key={node.id}
              style={{
                margin: '0 auto 10px auto',
                padding: '3px',
                marginBottom: '5px',
              }}
            >
              <div>
                <h2>@{node.user.screen_name}</h2>
                <p>{node.full_text}</p>
                <p>
                  {node.retweet_count} Retweets, {node.favorite_count} Favorites
                </p>
              </div>
            </li>
          )
        )}
      </ul>
    </Box> */}
    <div style={{ height: '10vh' }} />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    allTwitterStatusesUserTimelineSynthetikQuery {
      edges {
        node {
          id
          lang
          created_at
          full_text
          retweet_count
          favorite_count
          quoted_status_permalink {
            url
            expanded
            display
          }
          user {
            id
            screen_name
            name
            location
            profile_image_url_https
            profile_banner_url
          }
        }
      }
    }
    allTwitterSearchTweetsBlastFoamQuery {
      edges {
        node {
          id
          full_text
          created_at
          retweet_count
          favorite_count
          user {
            screen_name
            name
            profile_image_url_https
          }
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
