import { useStaticQuery, graphql } from 'gatsby';

export const useExamplesData = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ExamplesData {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/content/examples/" } }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              id
              frontmatter {
                date(formatString: "MMM DD, YYYY")
                path
                title
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges;
};
