import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Figure = styled.figure`
  background: white;
  border: 0.1px solid white;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  height: 100%;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }

  ${MEDIA.TABLET`
    margin-top: 20px;
  `}
`;

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
